<template>
    <div class="stepsdemo-content">
        <Card>
           <template v-slot:title>
                {{$t('Información del Vehículo')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-4">
						<label for="state">{{$t('Tipo de Vehículo')}}</label>
						<Dropdown v-model="vehiculo.tipo" :options="tipos" optionLabel="nombre" :filter="true" placeholder="Seleccionar..." v-if="i18n.locale() == 'es'">
							<template #value="slotProps">
								<div class="p-dropdown-car-value" v-if="slotProps.value">
									<span> {{slotProps.value.nombre}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span> {{slotProps.option.nombre}}</span>
								</div>
							</template>
						</Dropdown>
						<Dropdown v-model="vehiculo.tipo" :options="tipos" optionLabel="name" :filter="true" placeholder="Select..." v-if="i18n.locale() == 'en'">
							<template #value="slotProps">
								<div class="p-dropdown-car-value" v-if="slotProps.value">
									<span> {{slotProps.value.name}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span> {{slotProps.option.name}}</span>
								</div>
							</template>
						</Dropdown>
                        <small v-show="validationErrors.tipo && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label for="state">{{$t('Marca')}}</label>
						<Dropdown v-model="vehiculo.marca" :options="marcas" optionLabel="nombre" :filter="true" placeholder="...?" @change="vermodelos()">
							<template #value="slotProps">
								<div class="p-dropdown-car-value" v-if="slotProps.value">
									<span> {{slotProps.value.nombre}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span> {{slotProps.option.nombre}}</span>
								</div>
							</template>
						</Dropdown>
                        <small v-show="validationErrors.marca && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label for="state">{{$t('Módelo')}}</label>
						<Dropdown v-model="vehiculo.modelo" :options="modelos" optionLabel="nombre" :filter="true" placeholder="...?">
							<template #value="slotProps">
								<div class="p-dropdown-car-value" v-if="slotProps.value">
									<span> {{slotProps.value.nombre}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span> {{slotProps.option.nombre}}</span>
								</div>
							</template>
						</Dropdown>
                        <small v-show="validationErrors.modelo && submitted" class="p-error">{{$t('required')}}</small>
					</div>
                    <div class="p-field p-col-12 p-md-2">
                    <label>{{$t('Año de Referencia')}}</label>
						<InputMask v-model="vehiculo.compra" mask="9999"/>
                        <small v-show="validationErrors.compra && submitted" class="p-error">{{$t('required')}}</small>
                    </div>
					<div class="p-field p-col-12 p-md-1">
						<label for="state">{{$t('Automático')}}</label>
                        <Checkbox v-model="vehiculo.automatico" :binary="true" />
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label>{{$t('Nº placa')}}</label>
						<InputText v-model="vehiculo.placa" type="text"/>
                        <small v-show="validationErrors.placa && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label>{{$t('Número de chasis')}}</label>
						<InputText v-model="vehiculo.chasis" type="text"/>
                        <small v-show="validationErrors.chasis && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-2">
						<label>{{$t('Nº asientos')}}</label>
						<InputNumber v-model="vehiculo.asientos" min="1" max="100" integeronly showButtons />
                        <small v-show="validationErrors.asientos && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label>{{$t('Color')}}</label>
						<InputText v-model="vehiculo.color" type="text"/>
                        <small v-show="validationErrors.color && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label>{{$t('Posición del Volante')}}</label>
						<SelectButton v-model="vehiculo.volante" :options="options" />
					</div>
                </div>
				<h4>Keuringskaart</h4>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-2 p-md-2">
					<label>{{$t('Fecha de emisión')}}</label>
                        <InputMask v-model="vehiculo.cardemi" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        <small v-show="validationErrors.cardemi && submitted" class="p-error">{{$t('required')}}</small>
                    </div>
					<div class="p-field p-col-2 p-md-2">
                    <label>{{$t('Validez')}}</label>
						<Dropdown v-model="vehiculo.cardval" :options="validez" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                    </div>
					<div class="p-field p-col-2 p-md-2">
						<label>{{$t('Tarjeta')}}</label>
						<FileUpload :chooseLabel="$t('Subir Foto')+' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(img)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small v-show="validationErrors.foto && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-3 p-md-3">
						<img style="width: 100px;" :src="url.upload+vehiculo.adverso" v-if="foto"/>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";
import url from "@/service/_URL";

export default {
    props: {
        formData: Object
    },
    data () {
        return {
            submitted: false,
            validationErrors: {},
			marcas: [],
			modelos: [],
			years: [],
			validez: [],
			tipos: null,
			vehiculo: {
				marca: null,
				modelo: null,
				compra: null,
				automatico: true,
				placa: null,
				motor: null,
				chasis: null,
				año: null,
				asientos: null,
				color: null,
				tipo: null,
				adverso: null,
				reverso: null,
				cardreg: null,
				cardemi: null,
				cardval: null,
				volante: '<- Left'
			},
            Dialog: false,
            size: '40vw',
			foto: false,
			img: null,
			options: ['<- Left', 'Right->'],
        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '80vw';}
		this.$store.commit('Loading');
		this.i18n = useI18nPlugin();
        this.url = url;
        const Consulta = new API('Cotizacion');
		Consulta.Ini('Vehiculo').then(result => {
			if (result.consult.marcas){
				this.marcas = result.consult.marcas;
			}
			if (result.consult.tipos){
				this.tipos = result.consult.tipos;
				if(this.formData.datos.tipocliente.code==1){
					this.tipos = this.tipos.filter(val => val.code !== 6);
				}
			}
			if (result.consult.validez){
				this.validez = result.consult.validez;
			}      
			this.$store.commit('Loading');   
		}); 
		const tokgen = new Token(256);
		this.img = tokgen.generate();
	},
    methods: {
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {vehiculo: this.vehiculo}, pageIndex: 1});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        },
        vermodelos() {
			if(this.vehiculo.marca.code>0){
				this.$store.commit('Loading');
				this.modelos = [];
				this.years = [];
				this.vehiculo.modelo = null;
				this.vehiculo.año = null;
				const Consulta = new API('Cotizacion');
				Consulta.Procesar('Vehiculo',{
					caso: 	'vermodelos',
					marca: this.vehiculo.marca.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.modelos = response.result;
					}
					this.$store.commit('Loading');
				});
			}
        },
        veryear() {
			if(this.vehiculo.modelo.code>0){
				this.$store.commit('Loading');
				this.years = [];
				this.vehiculo.año = null;
				const Consulta = new API('Cotizacion');
				Consulta.Procesar('Vehiculo',{
					caso: 	'veryear',
					modelo: this.vehiculo.modelo.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.years = response.result;
					}
					this.$store.commit('Loading');	           
				});
			}
        },
        Carnet() {
            this.Dialog = true;
        },
		myUploader(img) {
			this.$store.commit('Loading');
			const Consulta = new API('Cotizacion');
			Consulta.Procesar('Vehiculo',{
				caso: 	'RevFoto',
				foto: img,
			}).then(response => {
				if(response.result){
					this.vehiculo.adverso =  response.result;
					this.foto = response.result;
				} else {
					this.foto = response.result;
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
        validateForm() {
            if (!this.vehiculo.marca)
               this.validationErrors['marca'] = true;
            else
               delete this.validationErrors['marca'];
            if (!this.vehiculo.modelo)
               this.validationErrors['modelo'] = true;
            else
               delete this.validationErrors['modelo'];
            if (!this.vehiculo.compra)
               this.validationErrors['compra'] = true;
            else
               delete this.validationErrors['compra'];
            if (!this.vehiculo.placa)
               this.validationErrors['placa'] = true;
            else
               delete this.validationErrors['placa'];
            if (!this.vehiculo.chasis)
               this.validationErrors['chasis'] = true;
            else
               delete this.validationErrors['chasis'];
            if (!this.vehiculo.tipo)
               this.validationErrors['tipo'] = true;
            else
               delete this.validationErrors['tipo'];
            if (!this.vehiculo.asientos)
               this.validationErrors['asientos'] = true;
            else
               delete this.validationErrors['asientos'];
            if (!this.vehiculo.color)
               this.validationErrors['color'] = true;
            else
               delete this.validationErrors['color'];
            if (this.vehiculo.cardemi.length!=10)
               this.validationErrors['cardemi'] = true;
            else
               delete this.validationErrors['cardemi'];
			if (!this.foto)
               this.validationErrors['foto'] = true;
            else
               delete this.validationErrors['foto'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>